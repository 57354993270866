import { defineAsyncComponent, markRaw } from 'vue'
export default {
  menu: [
    {
      label: "Reenvío de recibo",
      iconClass: "pt-1",
      iconComponent: "IconTicket",
      dispatch: "ticketoffice/toggleModelConsultPaymentOrders",
    },
    {
      label: "Consulta y retiro de saldo",
      iconClass: "pt-1",
      iconComponent: "IconSearch",
      dispatch: "ticketoffice/toggleModalBalanceWithdrawal",
    },
    {
      label: "Reportes",
      icon: "pi pi-chart-bar icon-color pt-1",
      showsidebar: {
        component: markRaw(defineAsyncComponent(() => import("../views/ticketoffice/reports/TicketofficeReport.vue"))),
      },
    },
  ],
};
